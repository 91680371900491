'use client'

import parse from 'html-react-parser'
import clsx from 'clsx'
import { useCallback, useMemo, type ReactNode } from 'react'
import useGetBanners from '~/components/hooks/useGetBanners'
import type { HeaderBannerAsset } from '~/lib/types/header-banner.interface'
import type { PromoBannerAsset } from '~/lib/types/promo-banner.interface'
import styles from './HeaderBanner.module.scss'
import { usePersonalizationInteractionHandlers } from '~/components/hooks/personalization/usePersonalizationInteractionHandlers'
import useReactiveActor from '~/components/hooks/useReactiveActor'
import { bannersStateMachine } from '~/components/cms/ContentSlot/statemachines/bannersStateMachine'
import { ensureNonNullishArray } from '~/types/strict-null-helpers'
import { matchesAny } from '~/lib/client-server/statemachine/statemachine-utils'
import { AnimationBanners, type BannerType } from '../AnimationBanners/AnimationBanners'

/**
 * Wrapper component for the header banner message.
 *
 * @component
 * @param {ReactNode} props.children - The content to be rendered inside the wrapper.
 * @param {string} [props.additionalClass] - Additional CSS class to be applied to the wrapper.
 * @returns {React.Element} The rendered header banner message wrapper.
 */
const BannerMessageWrapper = ({ children, additionalClass }: { children?: ReactNode; additionalClass?: string }) => (
	<div
		data-testid="header-banner-message"
		className={`${styles['header-banner-message']} bfx-remove-element ${additionalClass}`}
	>
		{children}
	</div>
)

/**
 * BannerContent component.
 *
 * @param {Object} props - Component props.
 * @param {BannerType} props.banner - The banner data.
 * @returns {JSX.Element} The parsed mobile message of the banner if it exists.
 */
const BannerContent = ({ banner }: { banner: BannerType }) => {
	return <>{banner?.promoMobileMessage && parse(banner.promoMobileMessage)}</>
}

/**
 * `HeaderBanner` is a React component that displays a header banner.
 *
 * @component
 * @param {HeaderBannerAsset} headerBanner - The header banner asset to be displayed.
 * @param {PromoBannerAsset} promoBanner - The promotional asset to be displayed in the banner.
 * @returns {React.Element} Returns a header banner based on the provided assets and device type.
 *
 * @example
 * <HeaderBanner headerBanner={headerBanner} promoBanner={promoBanner} />
 */
export default function HeaderBanner({
	headerBanner: headerAssetProps,
	promoBanner: promoAssetProps,
}: {
	headerBanner?: HeaderBannerAsset
	promoBanner?: PromoBannerAsset
}) {
	const { assets, personalizedPromoBannerLoaded, defaultAnimationStarted } = useGetBanners(
		headerAssetProps,
		promoAssetProps,
	)
	const isPersonalizedBannerLoadedOrAnimationStarted = personalizedPromoBannerLoaded || defaultAnimationStarted
	const { headerBanner, promoBanner, personalizedPromoBanner } = assets
	const banners = useMemo(
		() => ensureNonNullishArray([personalizedPromoBanner, promoBanner, headerBanner]),
		[personalizedPromoBanner, headerBanner, promoBanner],
	)
	// Campaign, experience, and user group fields are accessible only from the personalization promo asset.
	const personalizationContext = useMemo(
		() => ({
			context: {
				campaign: personalizedPromoBanner?.campaign || undefined,
				experience: personalizedPromoBanner?.experience || undefined,
				userGroup: personalizedPromoBanner?.userGroup || undefined,
			},
			source: personalizedPromoBanner?.source,
		}),
		[personalizedPromoBanner],
	)

	const { handleClickThroughEvent } = usePersonalizationInteractionHandlers(personalizationContext)

	const [actorState] = useReactiveActor(bannersStateMachine, {
		input: {
			hasSsfcHeaderData: !!headerBanner,
			hasSsfcPromoData: !!promoBanner,
			hasMcpPromoData: !!personalizedPromoBanner,
		},
	})

	const shouldShowDesktopHeaderBanner = actorState.matches({ desktop: { header: 'showSsfcHeader' } })
	const shouldShowMobileHeaderBanner = matchesAny(actorState, [
		{ mobile: 'showSsfcHeader' },
		{ mobile: 'showSsfcPromo' },
		{ mobile: 'showMcpPromo' },
		{ mobile: 'cycleSsfcHeaderAndPromo' },
		{ mobile: 'cycleSsfcHeaderAndMcpPromo' },
		{ mobile: 'cycleSsfcAndMcpPromo' },
		{ mobile: 'cycleAllData' },
	])
	const includeMcpPromo = matchesAny(actorState, [
		{ mobile: 'showMcpPromo' },
		{ mobile: 'cycleSsfcHeaderAndMcpPromo' },
		{ mobile: 'cycleSsfcAndMcpPromo' },
		{ mobile: 'cycleAllData' },
	])

	const handleClickInteraction = useCallback(() => {
		if (includeMcpPromo) {
			handleClickThroughEvent()
		}
	}, [includeMcpPromo, handleClickThroughEvent])

	return (
		<>
			{shouldShowDesktopHeaderBanner && (
				<BannerMessageWrapper additionalClass={styles.desktop}>
					{headerBanner?.body && parse(headerBanner.body)}
				</BannerMessageWrapper>
			)}
			{shouldShowMobileHeaderBanner && (
				<BannerMessageWrapper additionalClass={styles.mobile}>
					{/* This solution ensures that a banner is displayed immediately, and the animation is only started once all banners have been loaded or
						if we haven't had personalization and > 1 banner (E.g: sfcc header and sfcc promo banners), we wait for some seconds(5s) and start animation.
						This prevents the animation from being disrupted when the personalizedPromoBanner is loaded.
						Once the personalizedPromoBanner is loaded or start animation between sfcc banners, the PromoBannerContent component is styled with a fade-out animation (styles.fadeOut),
						and the AnimationBanners component is rendered. This component handles the animation of the banners.
				*/}
					<div
						className={clsx(styles.banner, {
							[styles.fadeOut]: isPersonalizedBannerLoadedOrAnimationStarted,
						})}
					>
						<BannerContent banner={headerBanner || promoBanner} />
					</div>
					{isPersonalizedBannerLoadedOrAnimationStarted && (
						<AnimationBanners
							onClick={handleClickInteraction}
							personalizedPromoBanner={personalizedPromoBanner}
							banners={banners}
							component={BannerContent}
						/>
					)}
				</BannerMessageWrapper>
			)}
		</>
	)
}
